<template>
  <span class="cut-text" @mouseenter="update" ref="container">
    <tooltip :title="alternativeText ?? text" :disabled="!needTooltip">
      <span class="cut-text__content" ref="content">{{ text }}</span>
    </tooltip>
    <span class="cut-text__copy" ref="copy">{{ text }}</span>
  </span>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import Tooltip from "@/components/tooltips/tool-tip.vue";

const props = defineProps<{ text: string; alternativeText?: string; forceTooltip?: boolean }>();

const container = ref<HTMLElement | null>();
const content = ref<HTMLElement | null>();
const copy = ref<HTMLElement | null>();

const needTooltip = ref(false);

const getWidth = (e: HTMLElement | null) => e?.getBoundingClientRect()?.width || 0;

const update = () => {
  if (!!container.value && !!content.value && !!copy.value) {
    needTooltip.value = props.forceTooltip || getWidth(copy.value) > getWidth(content.value);
  }
};
</script>

<style lang="scss" scoped>
.cut-text {
  position: relative;
  display: block;
  overflow: hidden;
  line-height: 1.28;
  &__content {
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  &__copy {
    white-space: nowrap;
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 6px;
    pointer-events: none;
  }
}
</style>
