<template>
  <article
    :style="style"
    :tabindex="!!action || !!overlayAction?.action ? 0 : -1"
    class="entity-card"
    @click="() => action?.()"
    @keydown.enter="() => action?.()"
    v-class-mod:entity-card="{
      hasAction: !!action,
      hasOverlayAction: !!overlayAction?.action,
      isActive,
      isDisabled,
      removeBorder,
      grow,
    }"
  >
    <div class="entity-card__content">
      <span
        v-if="progressBar"
        :style="`--width: ${progressBar.percentage}%; --color: var(--${progressBar.color})`"
        class="entity-card__progress-bar"
      />
      <div v-if="progressIcon || addonsBefore?.length" class="entity-card__addons">
        <in-progress v-if="progressIcon" v-bind="progressIcon" size="medium" class="entity-card__progress-icon" />
        <template v-for="(addon, key) in addonsBefore || []" :key="key">
          <span v-if="'text' in addon" :style="`--color: var(--${addon.color})`" class="entity-card__text">
            <tooltip v-if="addon?.tooltip" v-bind="addon.tooltip">
              <span>{{ addon.text }}</span>
            </tooltip>
            <template v-else>{{ addon.text }}</template>
          </span>
          <span
            v-else-if="'spacing' in addon"
            class="entity-card__spacing"
            v-class-mod:entity-card__spacing="addon.spacing"
          />
          <cta-button
            v-else-if="'title' in addon"
            v-bind="addon"
            class="entity-card__cta-button"
            @click.stop
            @mousedown.stop
          />
          <icon-toggle-button
            v-else-if="'action' in addon"
            v-bind="addon"
            button-size="medium"
            icon-size="medium"
            class="entity-card__icon-btn"
            @click.stop
            @mousedown.stop
          />
          <icon-wrapper v-else v-bind="addon" class="entity-card__icon" />
        </template>
      </div>
      <div class="entity-card__main">
        <bread-crumbs-component
          v-if="breadCrumbs"
          v-bind="breadCrumbs"
          class="entity-card__crumbs"
          @click="
            (e) => {
              if (breadCrumbs?.crumbs.some((c) => !!c.navigate)) {
                e.stopPropagation();
              }
            }
          "
          @mousedown="
            (e) => {
              if (breadCrumbs?.crumbs.some((c) => !!c.navigate)) {
                e.stopPropagation();
              }
            }
          "
        />
        <h3 class="entity-card__title" v-class-mod:entity-card__title="{ titleStrikeThrough, titleDotDot }">
          <span v-if="orderDisplay" class="entity-card__order-display">
            {{ orderDisplay }}
          </span>
          <cut-text v-if="enableCutText" :text="title" />
          <template v-else>{{ title }}</template>
        </h3>
        <p v-if="explanation" class="entity-card__explanation">
          <icon-wrapper
            v-if="explanation.icon"
            :icon="explanation.icon"
            :color="explanation.color"
            :tooltip="explanation.tooltip"
            size="small"
            class="entity-card__explanation-icon"
          />
          <cut-text v-if="enableCutText" :text="explanation.text" />
          <template v-else>{{ explanation.text }}</template>
        </p>
        <p v-if="startTime" class="entity-card__start-time">
          <cut-text :text="startTime.format(DateFormat.HH_MM_DD_MONTH_YYYY)" /> -
          <in-progress :is-skeleton="true" size="medium" />
        </p>
      </div>
      <div v-if="addonsAfter?.length" class="entity-card__addons">
        <template v-for="(addon, key) in addonsAfter || []" :key="key">
          <span v-if="'text' in addon" :style="`--color: var(--${addon.color})`" class="entity-card__text">
            <tooltip v-if="addon?.tooltip" v-bind="addon.tooltip">
              <span>{{ addon.text }}</span>
            </tooltip>
            <template v-else>{{ addon.text }}</template>
          </span>
          <span
            v-else-if="'spacing' in addon"
            class="entity-card__spacing"
            v-class-mod:entity-card__spacing="addon.spacing"
          />
          <cta-button
            v-else-if="'title' in addon"
            size="medium"
            v-bind="addon"
            class="entity-card__cta-button"
            @click.stop
            @mousedown.stop
          />
          <icon-toggle-button
            v-else-if="'action' in addon"
            v-bind="addon"
            button-size="medium"
            icon-size="medium"
            class="entity-card__icon-btn"
            @click.stop
            @mousedown.stop
          />
          <icon-wrapper v-else v-bind="addon" class="entity-card__icon" />
        </template>
      </div>
      <role-tag v-if="role" v-bind="role" />
      <div v-if="button" class="entity-card__button">
        <cta-button v-bind="button" size="medium" />
      </div>
      <div
        v-if="overlayAction"
        :tabindex="1"
        class="entity-card__overlay-action"
        @mousedown.stop
        @click.stop="overlayAction?.action"
        @keydown.enter="overlayAction?.action"
        v-class-mod:entity-card__overlay-action="`theme-${overlayAction.theme}`"
      >
        <div class="entity-card__content">
          <h3 class="entity-card__title" v-class-mod:entity-card__title="{ titleStrikeThrough }">
            <span v-if="orderDisplay" class="entity-card__order-display">
              {{ orderDisplay }}
            </span>
            <cut-text v-if="enableCutText" :text="title" />
            <template v-else>{{ title }}</template>
          </h3>
        </div>
        <span class="entity-card__overlay-action-text">{{ overlayAction.title }}</span>
      </div>
    </div>
    <slot />
  </article>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { ColorName, SpacingSize } from "@horizon56/styles/types";
import { DateFormat, DateTime } from "@horizon56/time";

import CtaButton, { CtaButtonProps } from "@/components/buttons/cta-button.vue";
import IconToggleButton, { IconToggleButtonProps } from "@/components/buttons/icon-toggle-button.vue";
import BreadCrumbsComponent from "@/components/content/bread-crumbs.vue";
import CutText from "@/components/content/cut-text.vue";
import RoleTag from "@/components/content/role-tag.vue";
import IconWrapper, { IconWrapperProps } from "@/components/icons/icon-wrapper.vue";
import InProgress from "@/components/icons/in-progress.vue";
import Tooltip, { TooltipProps } from "@/components/tooltips/tool-tip.vue";

type SpacingAddon = { spacing: SpacingSize };
type TextAddon = { text: string; color: ColorName; tooltip?: TooltipProps };

export type Addon = IconWrapperProps | IconToggleButtonProps | SpacingAddon | TextAddon | CtaButtonProps;

type OverlayAction = {
  action?: () => void;
  theme: "light-blue" | "black";
  title: string;
};

export type EntityCardProps = {
  action?: () => void;
  addonsAfter?: Addon[];
  addonsBefore?: Addon[];
  background?: ColorName;
  backgroundActive?: ColorName;
  backgroundHover?: ColorName;
  borderColor?: ColorName;
  borderColorActive?: ColorName;
  borderColorHover?: ColorName;
  breadCrumbs?: InstanceType<typeof BreadCrumbsComponent>["$props"];
  button?: CtaButtonProps;
  enableCutText?: boolean;
  explanation?: { text: string } & IconWrapperProps;
  isActive?: boolean;
  isDisabled?: boolean;
  orderDisplay?: string;
  overlayAction?: OverlayAction;
  progressBar?: { color: ColorName; percentage: number };
  progressIcon?: InstanceType<typeof InProgress>["$props"];
  removeBorder?: boolean;
  grow?: boolean;
  role?: InstanceType<typeof RoleTag>["$props"];
  startTime?: DateTime;
  title: string;
  titleStrikeThrough?: boolean;
  titleDotDot?: boolean;
};

const props = withDefaults(defineProps<EntityCardProps>(), {
  enableCutText: true,
  background: "menu-hover",
  backgroundHover: "menu-active",
  backgroundActive: "menu-active",
  borderColor: "transparent",
  borderColorHover: "transparent",
  borderColorActive: "transparent",
});

const style = computed(
  () => `
  --config-background: var(--${props.background});
  --config-background-hover: var(--${props.backgroundHover});
  --config-background-active: var(--${props.backgroundActive});
  --config-border-color: var(--${props.borderColor});
  --config-border-color-hover: var(--${props.borderColorHover});
  --config-border-color-active: var(--${props.borderColorActive});
  `,
);
</script>

<style lang="scss" scoped>
.entity-card {
  --border-width: 2px;
  --border-color: var(--config-border-color);
  --background: var(--config-background);
  --padding-top-bottom: var(--app-spacing-size-xsmall);
  --padding-left-right: var(--app-spacing-size-small);
  --border-radius: var(--app-radius-small);

  display: flex;
  flex-flow: column;
  user-select: none;
  justify-content: center;
  user-select: none;
  min-height: var(--app-button-height-large);
  border-radius: var(--border-radius);
  padding: calc(var(--padding-top-bottom) - var(--border-width)) calc(var(--padding-left-right) - var(--border-width));
  background: var(--background);
  border: var(--border-width) solid var(--border-color);
  row-gap: var(--app-spacing-size-small);
  position: relative;
  flex-shrink: 0;
  @include focus-outline;
  &__overlay-action-content,
  &__content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    column-gap: var(--app-spacing-size-small);
  }
  &__progress-bar {
    position: absolute;
    pointer-events: none;
    @include position-corner(calc(var(--border-width) * -1));
    border-radius: var(--border-radius);
    overflow: hidden;
    z-index: 0;
    &:before {
      content: "";
      position: absolute;
      @include position-corner;
      right: unset;
      width: var(--width);
      background: var(--color);
    }
  }
  &__main {
    display: flex;
    flex-flow: column;
    row-gap: 1px;
    margin-right: auto;
  }
  &__addons {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-shrink: 0;
  }
  &__cta-button {
    flex-shrink: 0;
  }
  &__cta-button,
  &__progress-icon,
  &__icon,
  &__text,
  &__text + &__icon-btn,
  &__spacing {
    &:not(:first-child) {
      margin-left: var(--app-spacing-size-xsmall);
    }
  }
  &__icon-btn + *:not(&__main):not(&__cta-button) {
    margin-left: 0;
  }
  &__addons:has(#{&}__icon-btn:last-child) + &__main {
    margin-left: calc(var(--app-spacing-size-small) * -1);
  }
  &__text {
    font-weight: bold;
    color: var(--color);
  }
  &__spacing {
    display: inline-flex;
    @each $size in $spacingSize {
      &--#{$size} {
        width: var(--app-spacing-size-#{$size});
      }
    }
  }
  &__order-display {
    text-align: center;
    transform: translateY(-0.5px);
    margin-right: var(--app-spacing-size-small);
  }
  &__title {
    display: flex;
    flex-flow: row nowrap;
    line-height: 1.34;
    &--titleStrikeThrough {
      color: var(--black-50);
      text-decoration: line-through;
    }
    &--titleDotDot {
      @include dotdot;
    }
  }
  &__explanation,
  &__start-time {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: var(--black-50);
    column-gap: var(--app-spacing-size-xsmall);
  }
  &__explanation {
    align-items: flex-start;
    line-height: 1.2;
    &-icon {
      margin-top: 1px;
    }
  }
  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: var(--padding-left-right);
    display: none;
    border-radius: var(--app-radius-large);
    background: var(--background);
  }
  &__overlay-action {
    --overlay-action-color: var(--light-blue-vivid);
    --overlay-action-border: var(--light-blue-vivid);
    --overlay-action-background: var(--light-blue-100);
    position: absolute;
    @include position-corner(calc(var(--border-width) * -1));
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    display: none;
    cursor: pointer;
    background: var(--overlay-action-background);
    border-radius: var(--border-radius);
    padding-left: var(--app-spacing-size-xsmall);
    border: 2px solid var(--overlay-action-border);
    padding-right: calc(var(--padding-left-right) - 2px);
    &-text {
      margin-left: auto;
      color: var(--overlay-action-color);
      z-index: 1;
      font-weight: 500;
      padding-left: var(--app-spacing-size-xsmall);
    }
    &--theme-black {
      --overlay-action-color: var(--black-50);
      --overlay-action-border: var(--black-20);
      --overlay-action-background: var(--menu-hover);
    }
  }
  &__button {
    z-index: 2;
  }
  &:focus-visible #{&}__overlay-action,
  &:hover #{&}__overlay-action,
  &:hover #{&}__button {
    display: flex;
  }
  &__main:has(#{&}__title:only-child) {
    padding-top: var(--app-spacing-size-xsmall);
    padding-bottom: var(--app-spacing-size-xsmall);
  }
  &--hasOverlayAction,
  &--hasAction {
    cursor: pointer;
  }
  &--hasAction:not(#{&}--isActive):hover {
    --background: var(--config-background-hover);
    --border-color: var(--config-border-color-hover);
  }
  &--isActive {
    --background: var(--config-background-active);
    --border-color: var(--config-border-color-active);
  }
  &--isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &--removeBorder {
    --border-radius: unset;
  }
  &--grow {
    height: 100%;
    justify-content: flex-start;
  }
  &:has(#{&}__start-time),
  &:has(#{&}__explanation),
  &:has(#{&}__crumbs) {
    --padding-top-bottom: var(--app-spacing-size-xsmall);
  }
}
</style>
