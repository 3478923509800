<template>
  <span
    @mouseenter="hover"
    @mouseleave="cancel"
    @touchstart.passive="hover"
    @touchcancel.passive="cancel"
    @click="cancel"
    @touchend.passive="cancel"
    ref="tooltip"
  >
    <slot />
    <template v-if="!disabled && (title || explanation || richContent || infoSections?.length)">
      <app-teleport v-if="isOpen || hovering">
        <div :style="style" class="tooltip" ref="bubble" v-class-mod:tooltip="[`size-${size}`, { removeSpacing }]">
          <h3 v-if="title" class="tooltip__title">{{ title }}</h3>
          <template v-if="explanation">
            <p
              v-for="(line, key) in Array.isArray(explanation) ? explanation : [explanation]"
              class="tooltip__explanation"
              :key="key"
            >
              {{ line }}
            </p>
          </template>
          <template v-if="infoSections?.length">
            <info-section
              v-for="(section, key) in infoSections"
              :remove-spacing="true"
              v-bind="section"
              class="tooltip__info-section"
              :key="key"
            />
          </template>
          <template v-if="entityCards?.length">
            <entity-card v-for="(card, key) in entityCards" v-bind="card" class="tooltip__entity-card" :key="key" />
          </template>
          <keep-alive v-if="richContent">
            <component :is="richContent.component" v-bind="richContent?.props" />
          </keep-alive>

          <span v-if="shortcut" class="tooltip__shortcut">
            <span class="tooltip__shortcut-prefix">{{ translations.tooltip.shortcutPrefix }}</span>
            {{ shortcut }}
          </span>
        </div>
      </app-teleport>
    </template>
  </span>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

import { AppTeleport } from "@horizon56/bootstrap";
import { TooltipSize } from "@horizon56/styles/types";
import { eventHandler, isHtmlElem } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import EntityCard, { EntityCardProps } from "@/components/content/entity-card.vue";
import InfoSection, { InfoSectionProps } from "@/components/content/info-section.vue";

export type TooltipProps = {
  title?: string;
  richContent?: { component: any; props?: any };
  infoSections?: InfoSectionProps[];
  entityCards?: EntityCardProps[];
  size?: TooltipSize;
  disabled?: boolean;
  removeSpacing?: boolean;
  explanation?: string | string[] | { title: string; text: string };
  shortcut?: string;
  delay?: number;
  offset?: number;
  isOpen?: boolean;
  allowEntering?: boolean;
};

const props = withDefaults(defineProps<TooltipProps>(), {
  delay: 500,
  offset: 5,
  disabled: false,
  isOpen: false,
  allowEntering: false,
});

const xPosition = ref(-1);
const yPosition = ref(-1);
const hovering = ref<boolean>(false);
const delayTimer = ref<number>(-1);
const ticker = ref<number>(-1);

const tooltip = ref<HTMLElement>();
const bubble = ref<HTMLElement>();
const tooltipWrapperElements = ref<Element[]>([]);

const style = computed(() => {
  if (xPosition.value === -1 && yPosition.value === -1) {
    return undefined;
  }
  return `--h56-tooltip-x:${xPosition.value}px;--h56-tooltip-y:${yPosition.value}px`;
});

const limitNumber = (n: number, min: number, max: number) => (n < min ? min : n > max ? max : n);

const hover = () => {
  window.clearTimeout(delayTimer.value);
  delayTimer.value = window.setTimeout(() => (hovering.value = true), props.delay || 500);
};

const mousemove = (e: MouseEvent | TouchEvent) => {
  if (isHtmlElem(e.target) && (tooltip.value?.contains(e.target) || bubble.value?.contains(e.target))) {
    return;
  }
  window.clearTimeout(delayTimer.value);
  hovering.value = false;
};

const cancel = () => {
  if (props.allowEntering) {
    return;
  }
  window.clearTimeout(delayTimer.value);
  hovering.value = false;
};

const startObserve = () => tooltipWrapperElements.value.forEach((e) => resizeObserver.observe(e));

const endObserve = () => tooltipWrapperElements.value.forEach((e) => resizeObserver.unobserve(e));

const isValidChildNode = (e: any): e is Element =>
  e.nodeType === 1 && !(e as Element).classList.contains("h56-tooltip__bubble");

const findPlacement = () => {
  if (!bubble.value) {
    if (hovering.value || props.isOpen) {
      window.setTimeout(findPlacement, 50);
    }
    return;
  }

  let xMin = Number.MAX_SAFE_INTEGER;
  let xMax = Number.MIN_SAFE_INTEGER;
  let yMin = Number.MAX_SAFE_INTEGER;
  let yMax = Number.MIN_SAFE_INTEGER;

  const { width: bubbleWidth, height: bubbleHeight } = bubble.value.getBoundingClientRect();

  tooltipWrapperElements.value.forEach((el) => {
    const { x, y, width, height } = el.getBoundingClientRect();
    if (xMin === Number.MAX_SAFE_INTEGER || x < xMin) {
      xMin = x;
    }
    if (xMax === Number.MIN_SAFE_INTEGER || x + width > xMax) {
      xMax = x + width;
    }
    if (yMin === Number.MAX_SAFE_INTEGER || y < yMin) {
      yMin = y;
    }
    if (yMax === Number.MIN_SAFE_INTEGER || y + height > yMax) {
      yMax = y + height;
    }
  });

  const yPos = yMin < bubbleHeight + props.offset ? yMax + props.offset : yMin - bubbleHeight - props.offset;
  const xPos = xMin + (xMax - xMin) / 2 - bubbleWidth / 2;

  yPosition.value = limitNumber(yPos, 0, window.innerHeight - bubbleHeight);
  xPosition.value = limitNumber(xPos, 0, window.innerWidth - bubbleWidth);
};

const reset = () => {
  yPosition.value = -1;
  xPosition.value = -1;
};
const resizeObserver: ResizeObserver = new ResizeObserver(findPlacement);

const updateWrapperElements = () => {
  tooltipWrapperElements.value = [];
  tooltip.value?.childNodes.forEach((e) => {
    if (isValidChildNode(e)) {
      tooltipWrapperElements.value.push(e);
    }
  });
};

const startTicker = () => (ticker.value = window.setInterval(findPlacement, 250));

const removeTicker = () => window.clearInterval(ticker.value);

const { add, remove } = eventHandler(window, [["mousemove", mousemove]]);

watch(
  () => hovering.value || props.isOpen,
  (hover) => {
    if (hover) {
      updateWrapperElements();
      startObserve();
      removeTicker();
      findPlacement();
      startTicker();
      if (props.allowEntering) {
        add();
      }
    } else {
      removeTicker();
      reset();
      if (props.allowEntering) {
        remove();
      }
    }
  },
  { immediate: props.isOpen },
);

onBeforeUnmount(() => {
  remove();
  endObserve();
  window.clearTimeout(delayTimer.value);
  if (hovering.value || props.isOpen) {
    removeTicker();
  }
});

onMounted(() => {
  if (props.isOpen) {
    updateWrapperElements();
    startObserve();
    findPlacement();
  }
});
</script>

<style lang="scss">
.tooltip {
  position: fixed;
  display: flex;
  flex-flow: column;
  pointer-events: none;
  z-index: 999999999999;
  left: var(--h56-tooltip-x);
  top: var(--h56-tooltip-y);
  color: var(--black-90);
  background: var(--content-hover);
  border-radius: var(--app-radius-medium);
  border: 1px solid var(--black-10);
  will-change: left, top;
  @include sticky-content;
  padding: var(--app-spacing-size-small) var(--app-spacing-size-medium);
  width: var(--tooltip-width);
  max-width: var(--app-tooltip-size-#{list-nth($tooltipSizes, -1)});
  &--removeSpacing {
    padding: 0;
  }
  &__title {
    text-align: center;
    &:not(:last-child) {
      margin-bottom: var(--app-spacing-size-xsmall);
    }
  }
  &__explanation {
    text-align: center;
    color: var(--black-50);
    + #{&} {
      margin-top: var(--app-spacing-size-xsmall);
    }
  }
  &__entity-card {
    margin-top: var(--app-spacing-size-xsmall);
  }
  &__info-section + &__info-section {
    margin-top: var(--app-spacing-size-small);
  }
  &__shortcut {
    width: 100%;
    margin-top: 10px;
    border-top: 1px solid var(--black-20);
    padding-top: 10px;
    text-align: center;
    &-prefix {
      color: var(--black-50);
    }
  }
  @each $size in $tooltipSizes {
    &--size-#{$size} {
      --tooltip-width: var(--app-tooltip-size-#{$size});
    }
  }
}
</style>
