<template>
  <button
    :style="styles"
    :disabled="disabled"
    :autofocus="autofocus"
    :tabindex="disabled ? -1 : 0"
    class="cta-button"
    @click="handleAction"
    @keydown.enter="handleAction"
    ref="button"
    v-class-mod:cta-button="[{ alignCenter }, `size-${size}`]"
  >
    <feedback-icon v-if="feedback" v-bind="feedback" :size="size" class="cta-button__feedback" />
    <span v-else class="cta-button__btn">
      <span
        v-if="iconBefore"
        :class="`h56-icons-before--${iconBefore}`"
        class="cta-button__icon cta-button__icon--before"
      />
      <span class="cta-button__title">
        <cut-text :text="title" />
        <p v-if="explanation" class="cta-button__explanation">{{ explanation }}</p>
      </span>
      <icon-indicator v-if="indicator" v-bind="indicator" class="cta-button__indicator" />
      <span
        v-if="iconAfter"
        :class="`h56-icons-before--${iconAfter}`"
        class="cta-button__icon cta-button__icon--after"
      />
    </span>
    <loader-bars v-if="isLoading || internalIsLoading" />
  </button>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, ref, watch } from "vue";

import { IconName } from "@horizon56/fonts/types";
import { ButtonSize, ColorName, RadiusSize } from "@horizon56/styles/types";
import { DeepPartial } from "@horizon56/utils";

import CutText from "@/components/content/cut-text.vue";
import FeedbackIcon, { FeedbackIconProps } from "@/components/icons/feedback-icon.vue";

const IconIndicator = defineAsyncComponent(() => import("@/components/icons/icon-indicator.vue"));
const LoaderBars = defineAsyncComponent(() => import("@/components/loaders/loader-bars.vue"));

type ButtonTheme = "gray" | "green" | "red" | "blue" | "lightBlue" | "purple" | "yellow" | "transparent";

type Feedback = Omit<FeedbackIconProps, "size">;
export type FeedbackStatus = "success" | "failure";
export type FeedbackState = { state: FeedbackStatus; title: string; callback?: () => void };

type ButtonRespons = boolean | void | FeedbackState;

export type ButtonStylingOptions = {
  color: ColorName;
  colorHover: ColorName;
  bg: ColorName;
  bgHover: ColorName;
  disabledColor: ColorName;
  disabledBg: ColorName;
  iconBeforeColor: ColorName;
  iconBeforeColorHover: ColorName;
  iconBeforeColorDisabled: ColorName;
  iconAfterColor: ColorName;
  iconAfterColorHover: ColorName;
  iconAfterColorDisabled: ColorName;
};

export type CtaButtonProps = {
  action: (event: MouseEvent | KeyboardEvent) => ButtonRespons | Promise<ButtonRespons>;

  title: string;
  explanation?: string;

  radius?: RadiusSize;
  size?: ButtonSize;

  isLoading?: boolean;
  disabled?: boolean;

  iconBefore?: IconName;
  iconAfter?: IconName;

  theme?: ButtonTheme;
  removeBorder?: boolean;
  alignCenter?: boolean;
  stylingOptions?: DeepPartial<ButtonStylingOptions>;

  autofocus?: boolean;
  indicator?: Pick<InstanceType<typeof IconIndicator>, "icon" | "tooltip" | "indicator">;

  feedbackIcon?: Feedback;
};

const props = withDefaults(defineProps<CtaButtonProps>(), {
  size: "large",
  radius: "large",
  theme: "gray",
});

const button = ref<HTMLButtonElement>();
const feedbackDuration = 1300;

const buttonThemes: {
  [key in ButtonTheme]: ButtonStylingOptions;
} = {
  gray: {
    color: "black-90",
    colorHover: "black-90",
    bg: "black-10",
    bgHover: "black-20",
    disabledColor: "black-20",
    disabledBg: "black-10",

    iconBeforeColor: "black-50",
    iconBeforeColorHover: "black-90",
    iconBeforeColorDisabled: "black-20",
    iconAfterColor: "black-50",
    iconAfterColorHover: "black-90",
    iconAfterColorDisabled: "black-20",
  },
  green: {
    color: "static-white",
    colorHover: "static-white",
    bg: "green-500",
    bgHover: "green-600",
    disabledColor: "static-white-20",
    disabledBg: "green-100",
    iconBeforeColor: "black-50",
    iconBeforeColorHover: "black-90",
    iconBeforeColorDisabled: "black-20",
    iconAfterColor: "black-50",
    iconAfterColorHover: "black-90",
    iconAfterColorDisabled: "black-20",
  },
  blue: {
    color: "static-white",
    colorHover: "static-white",
    bg: "blue-500",
    bgHover: "blue-600",
    disabledColor: "static-white-20",
    disabledBg: "blue-100",
    iconBeforeColor: "black-50",
    iconBeforeColorHover: "black-90",
    iconBeforeColorDisabled: "black-20",
    iconAfterColor: "black-50",
    iconAfterColorHover: "black-90",
    iconAfterColorDisabled: "black-20",
  },
  lightBlue: {
    color: "static-white",
    colorHover: "static-white",
    bg: "light-blue-500",
    bgHover: "light-blue-600",
    disabledColor: "static-white-20",
    disabledBg: "light-blue-100",
    iconBeforeColor: "black-50",
    iconBeforeColorHover: "black-90",
    iconBeforeColorDisabled: "black-20",
    iconAfterColor: "black-50",
    iconAfterColorHover: "black-90",
    iconAfterColorDisabled: "black-20",
  },
  purple: {
    color: "static-white",
    colorHover: "static-white",
    bg: "purple-500",
    bgHover: "purple-600",
    disabledColor: "static-white-20",
    disabledBg: "purple-100",
    iconBeforeColor: "black-50",
    iconBeforeColorHover: "black-90",
    iconBeforeColorDisabled: "black-20",
    iconAfterColor: "black-50",
    iconAfterColorHover: "black-90",
    iconAfterColorDisabled: "black-20",
  },
  red: {
    color: "static-white",
    colorHover: "static-white",
    bg: "red-500",
    bgHover: "red-600",
    disabledColor: "static-white-20",
    disabledBg: "red-100",
    iconBeforeColor: "black-50",
    iconBeforeColorHover: "black-90",
    iconBeforeColorDisabled: "black-20",
    iconAfterColor: "black-50",
    iconAfterColorHover: "black-90",
    iconAfterColorDisabled: "black-20",
  },
  yellow: {
    color: "static-black",
    colorHover: "static-black",
    bg: "yellow-500",
    bgHover: "yellow-600",
    disabledColor: "static-white-20",
    disabledBg: "yellow-100",
    iconBeforeColor: "black-50",
    iconBeforeColorHover: "black-90",
    iconBeforeColorDisabled: "black-20",
    iconAfterColor: "black-50",
    iconAfterColorHover: "black-90",
    iconAfterColorDisabled: "black-20",
  },
  transparent: {
    color: "black-90",
    colorHover: "black-90",
    bg: "transparent",
    bgHover: "black-10",
    disabledColor: "black-20",
    disabledBg: "transparent",
    iconBeforeColor: "black-50",
    iconBeforeColorHover: "black-90",
    iconBeforeColorDisabled: "black-20",
    iconAfterColor: "black-50",
    iconAfterColorHover: "black-90",
    iconAfterColorDisabled: "black-20",
  },
};

const internalIsLoading = ref(false);
const internalFeedbackStatus = ref<FeedbackState | undefined>();
const internalFeedbackTimeout = ref(-1);

const isFeedback = (response: ButtonRespons): response is FeedbackState =>
  typeof response === "object" && "state" in response;

const handleAction = async (event: MouseEvent | KeyboardEvent) => {
  if (props.disabled || props.isLoading) {
    event.preventDefault();
    event.stopPropagation();
    return;
  }

  internalIsLoading.value = true;
  const result = (await props.action(event)) || undefined;
  if (isFeedback(result)) {
    internalFeedbackStatus.value = result;
  }
  internalIsLoading.value = false;
};

const feedback = computed((): Feedback | undefined => {
  if (props.feedbackIcon) {
    return props.feedbackIcon;
  }

  if (internalFeedbackStatus.value) {
    return {
      theme: internalFeedbackStatus.value.state === "success" ? "green" : "red",
      icon: internalFeedbackStatus.value.state === "success" ? "check_circle" : "error",
      title: internalFeedbackStatus.value.title,
    };
  }

  return undefined;
});

const styles = computed(() => {
  const _styles: string[] = [];

  const button = {
    ...buttonThemes[props.theme],
    ...props.stylingOptions,
  };
  _styles.push(`--button-color: var(--${button.color})`);
  _styles.push(`--button-colorHover: var(--${button.colorHover})`);
  _styles.push(`--button-bg: var(--${button.bg})`);
  _styles.push(`--button-bgHover: var(--${button.bgHover})`);
  _styles.push(`--button-disabledColor: var(--${button.disabledColor})`);
  _styles.push(`--button-disabledBg: var(--${button.disabledBg})`);
  if (!props.removeBorder) {
    _styles.push(`--button-radius: var(--app-radius-${props.radius ?? "large"})`);
  }

  _styles.push(`--button-iconBeforeColor: var(--${button.iconBeforeColor})`);
  _styles.push(`--button-iconBeforeColorHover: var(--${button.iconBeforeColorHover})`);
  _styles.push(`--button-iconBeforeColorDisabled: var(--${button.iconBeforeColorDisabled})`);
  _styles.push(`--button-iconAfterColor: var(--${button.iconAfterColor})`);
  _styles.push(`--button-iconAfterColorHover: var(--${button.iconAfterColorHover})`);
  _styles.push(`--button-iconAfterColorDisabled: var(--${button.iconAfterColorDisabled})`);

  return _styles.join(";");
});

watch(
  () => internalFeedbackStatus.value?.state,
  (state) => {
    if (state) {
      window.clearInterval(internalFeedbackTimeout.value);
      internalFeedbackTimeout.value = window.setTimeout(() => {
        internalFeedbackStatus.value?.callback?.();
        internalFeedbackStatus.value = undefined;
      }, feedbackDuration);
    }
  },
);

watch(
  () => props.autofocus,
  (has) => {
    if (has) {
      button.value?.focus();
    }
  },
);
</script>

<style lang="scss" scoped>
.cta-button {
  $block: &;
  @include focus-outline;
  background: transparent;
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  position: relative;
  border-radius: var(--button-radius);
  overflow: hidden;
  &__btn {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-shrink: 0;
    height: var(--button-height);
    width: 100%;
    gap: var(--app-spacing-size-small);
    user-select: none;
    border-radius: var(--button-radius);
    overflow: hidden;
    padding: 0 var(--app-spacing-size-large);
    color: var(--button-color);
    background: var(--button-bg);
  }
  &__icon {
    line-height: 1;
    flex-shrink: 0;
    font-size: var(--app-icon-size-medium);
    &--before {
      color: var(--button-iconBeforeColor);
    }
    &--after {
      color: var(--button-iconAfterColor);
    }
  }
  &__title {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
  &__explanation {
    color: var(--black-50);
    font-size: var(--app-font-size-label);
  }
  &__title + &__icon {
    margin-left: auto;
  }
  &__indicator:last-child {
    margin-left: auto;
  }
  @each $size in $buttonSizes {
    &--size-#{$size} {
      --button-height: var(--app-button-height-#{$size});
    }
  }

  &:has(#{&}__title + #{&}__indicator) &__title {
    margin-right: var(--app-spacing-size-small);
  }

  &:disabled {
    pointer-events: none;
    #{$block} {
      &__btn {
        color: var(--button-disabledColor);
        background: var(--button-disabledBg);
      }
      &__indicator {
        opacity: 0.3;
      }
      &__explanation {
        color: var(--black-20);
      }
      &__icon {
        &--before {
          color: var(--button-iconBeforeColorDisabled);
        }
        &--after {
          color: var(--button-iconAfterColorDisabled);
        }
      }
    }
  }
  &:not(:disabled):hover & {
    &__btn {
      cursor: pointer;
      color: var(--button-colorHover);
      background: var(--button-bgHover);
    }
    &__icon {
      &--before {
        color: var(--button-iconBeforeColorHover);
      }
      &--after {
        color: var(--button-iconAfterColorHover);
      }
    }
  }
  &:not([style*="--button-radius"]) & {
    &__btn {
      padding: 0 var(--app-spacing-size-small);
    }
    &__feedback {
      margin-left: var(--app-spacing-size-small);
    }
  }
  &[autofocus]:focus {
    @include focus-outline-inner;
  }
  &__indicator {
    margin-left: 10px;
  }
  &--alignCenter,
  &--alignCenter &__btn {
    justify-content: center;
  }
}
</style>
