<template>
  <tooltip v-if="longName || participants?.length" :title="title" :info-sections="infoSections">
    <span class="role-tag">
      <span v-if="statusCircle" class="role-tag__status-circle" v-set-colors="{ background: statusCircle }" />
      <span class="role-tag__label">{{ label ?? "-" }}</span>
    </span>
  </tooltip>
  <span v-else class="role-tag">
    <span
      v-if="statusCircle"
      :style="`--color: var(--${statusCircle})`"
      class="role-tag__status-circle"
      v-set-colors="{ background: statusCircle }"
    />
    <span class="role-tag__label">{{ label ?? "-" }}</span>
  </span>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { vSetColors } from "@horizon56/directives/set-colors";
import { ColorName } from "@horizon56/styles/types";
import { commaSeparatedList } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import Tooltip from "@/components/tooltips/tool-tip.vue";

type Role = { longName?: string; shortName?: string };

const format = (role: Role) => (role?.shortName ? `${role?.longName} (${role?.shortName})` : `${role?.longName}`);

const props = defineProps<Role & { participants?: Role[] } & { statusCircle?: ColorName }>();

const label = computed(() => props?.shortName);

const title = computed(() => (props.participants?.length ? undefined : props.longName));

const infoSections = computed(() => {
  if (props.participants?.length) {
    return [
      {
        title: translations.roleTag.responsible,
        explanation: format({ shortName: props.shortName, longName: props.longName }) ?? "-",
      },
      {
        title: translations.roleTag.participants,
        explanation: commaSeparatedList(
          props.participants.map((role) => format(role)),
          translations.roleTag.binding,
        ),
      },
    ];
  }
  return undefined;
});
</script>

<style lang="scss" scoped>
.role-tag {
  display: inline-flex;
  align-items: center;
  padding: 0px var(--app-spacing-size-small);
  line-height: 20px;
  height: 20px;
  gap: var(--app-spacing-size-xsmall);
  white-space: nowrap;
  color: var(--black-90);
  background: var(--black-20);
  border-radius: var(--app-radius-medium);
  font-size: var(--app-font-size-label);
  &__status-circle {
    width: var(--app-spacing-size-medium);
    height: var(--app-spacing-size-medium);
    border-radius: 50%;
  }
  &:has(#{&}__status-circle) {
    padding-left: var(--app-spacing-size-xsmall);
  }
}
</style>
