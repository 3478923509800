<template>
  <div
    :style="style"
    class="icon-toggle-button"
    v-class-mod:icon-toggle-button="[
      `icon-size-${iconSize || 'medium'}`,
      `button-size-${buttonSize || 'large'}`,
      `active-color-${activeColor || 'black'}`,
      { isDisabled, hasBackground: !!background, isActive, hoverOnParent },
    ]"
  >
    <div class="icon-toggle-button__wrapper">
      <feedback-icon v-if="feedback" v-bind="feedback" :size="buttonSize" />
      <template v-else>
        <tooltip v-bind="tooltip">
          <span
            :tabindex="isDisabled ? -1 : 0"
            class="icon-toggle-button__icon"
            @click="handleClick"
            @keydown.enter="handleEnter"
          >
            <span
              v-for="(i, k) in giveMeArray(icon)"
              :class="`h56-icons-after--${i}`"
              class="icon-toggle-button__icon-symbol"
              :key="k"
            />
          </span>
        </tooltip>

        <LoaderBars v-if="isLoading || internalIsLoading" class="icon-toggle-button__loader" />
        <span v-if="notificationCircle" class="icon-toggle-button__notification-circle" />
        <span
          v-else-if="isNumber(notificationAmount) && notificationAmount > 0"
          class="icon-toggle-button__notification-amount"
        >
          {{ notificationAmount }}
        </span>
      </template>
      <span v-if="backdrop?.color" class="icon-toggle-button__backdrop" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { IconName } from "@horizon56/fonts/types";
import { ButtonSize, ColorName, IconSize } from "@horizon56/styles/types";
import { giveMeArray, isNumber } from "@horizon56/utils";

import FeedbackIcon, { FeedbackIconProps } from "@/components/icons/feedback-icon.vue";
import LoaderBars from "@/components/loaders/loader-bars.vue";
import Tooltip, { TooltipProps } from "@/components/tooltips/tool-tip.vue";

type Feedback = Omit<FeedbackIconProps, "size">;
export type FeedbackStatus = "success" | "failure";
type FeedbackState = { state: FeedbackStatus; title: string };

type ButtonRespons = boolean | void | FeedbackState;

export type IconToggleButtonProps = {
  icon: IconName | IconName[];
  action: (event: MouseEvent | KeyboardEvent) => ButtonRespons | Promise<ButtonRespons>;
  iconColor?: ColorName;
  iconHoverColor?: ColorName;
  iconSize?: IconSize;
  buttonSize?: ButtonSize;
  isDisabled?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  background?: ColorName;
  tooltip?: TooltipProps;
  activeColor?: "light-blue" | "black";
  backdrop?: { color: ColorName; size: number; radius?: number };
  notificationCircle?: boolean;
  notificationAmount?: number;
  notificationColor?: ColorName;
  notificationBackground?: ColorName;
  feedbackIcon?: Feedback;
  hoverOnParent?: boolean;
};

const props = defineProps<IconToggleButtonProps>();
defineEmits<{ (e: "keydown.enter", a: KeyboardEvent): void }>();

const feedbackDuration = 1300;

const internalIsLoading = ref(false);
const internalFeedbackStatus = ref<FeedbackState | undefined>();
const internalFeedbackTimeout = ref(-1);

const style = computed(() => {
  const style: string[] = [];

  if (props.iconColor) {
    style.push(`--icon-color: var(--${props.iconColor})`);
  }
  if (props.iconHoverColor) {
    style.push(`--icon-hover-color: var(--${props.iconHoverColor})`);
  }
  if (props.background) {
    style.push(`--background-color: var(--${props.background})`);
  }
  if (props.backdrop) {
    if (props.backdrop?.color) {
      style.push(`--backdrop-color: var(--${props.backdrop.color})`);
    }
    if (isNumber(props.backdrop.size)) {
      style.push(`--backdrop-size: ${props.backdrop.size}px`);
    }
    if (isNumber(props.backdrop?.radius)) {
      style.push(`--backdrop-radius: ${props.backdrop.radius}%`);
    }
  }

  if (props.notificationColor) {
    style.push(`--notification-color: var(--${props.notificationColor})`);
  }

  if (props.notificationBackground) {
    style.push(`--notification-background: var(--${props.notificationBackground})`);
  }

  return style.join(";");
});

const handleAction = async (e: MouseEvent | KeyboardEvent) => {
  if (props.isDisabled) {
    return;
  }
  internalIsLoading.value = true;
  const result = (await props.action(e)) || undefined;
  if (isFeedback(result)) {
    internalFeedbackStatus.value = result;
  }
  internalIsLoading.value = false;
};

const handleClick = (e: MouseEvent) => {
  (e?.target as HTMLButtonElement)?.blur();
  handleAction(e);
};

const handleEnter = (e: KeyboardEvent) => {
  handleAction(e);
};

const isFeedback = (response: ButtonRespons): response is FeedbackState =>
  typeof response === "object" && "state" in response;

const feedback = computed((): Feedback | undefined => {
  if (props.feedbackIcon) {
    return props.feedbackIcon;
  }

  if (internalFeedbackStatus.value) {
    return {
      theme: internalFeedbackStatus.value.state === "success" ? "green" : "red",
      icon: internalFeedbackStatus.value.state === "success" ? "check_circle" : "error",
      title: internalFeedbackStatus.value.title,
    };
  }

  return undefined;
});

watch(
  () => internalFeedbackStatus.value?.state,
  (state) => {
    if (state) {
      window.clearInterval(internalFeedbackTimeout.value);
      internalFeedbackTimeout.value = window.setTimeout(() => {
        internalFeedbackStatus.value = undefined;
      }, feedbackDuration);
    }
  },
);
</script>

<style lang="scss" scoped>
@mixin hover {
  color: var(--icon-hover-color, var(--black-90));
  background: var(--black-20);
}
.icon-toggle-button {
  display: inline-flex;
  $block: &;
  &__icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: var(--app-spacing-size-xmall);
    justify-content: center;
    flex-shrink: 0;
    color: var(--black-50);
    border-radius: var(--size);
    user-select: none;
    outline: none;
    border: 2px solid transparent;
    display: inline-flex;
    height: var(--size);
    min-width: var(--size);
    color: var(--icon-color, var(--black-50));
    @include focus-outline;
    &:has(:not(#{$block}__icon-symbol:last-child:first-child)) {
      padding: 0 var(--app-spacing-size-small);
    }
  }
  &__icon-symbol {
    line-height: 1;
    display: flex;
    font-size: var(--font-size);
  }
  &:not(&--isDisabled) #{&}__icon:hover {
    @include hover;
  }
  &:not(&--isDisabled) #{&}__icon {
    cursor: pointer;
  }
  @at-root *:hover > #{&}:not(#{&}--isDisabled)#{&}--hoverOnParent #{&}__icon {
    @include hover;
  }

  @each $size in ("small", "medium", "large") {
    &--icon-size-#{$size} {
      --font-size: var(--app-icon-size-#{$size});
    }
    &--button-size-#{$size} {
      --size: var(--app-button-height-#{$size});
    }
  }

  &--isActive#{&}--active-color-black #{&}__icon {
    background: var(--black-20);
    color: var(--black-90);
    border: 2px solid var(--black-28);
  }
  &--isActive#{&}--active-color-light-blue #{&}__icon {
    background: var(--light-blue-200);
    color: var(--black-90);
    border: 2px solid var(--light-blue-500);
  }

  &--hasBackground #{&}__icon {
    background: var(--background-color, var(--black-10));
    @if (false) {
      &:before {
        content: "";
        border-radius: 50%;
        position: absolute;
        @include position-corner;
        z-index: -1;
        background: $extraBg;
      }
    }
  }

  &--isDisabled {
    opacity: 0.4;
  }

  &__wrapper {
    position: relative;
  }

  &__loader {
    position: absolute;
    bottom: 10%;
    left: 25%;
    right: 25%;
  }

  &__notification-circle,
  &__notification-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--notification-background, var(--yellow-vivid));
    color: var(--notification-color, var(--static-black));
    border-radius: 20px;
    position: absolute;
    pointer-events: none;
  }

  &__notification-circle {
    height: 10px;
    width: 10px;
    top: 1px;
    right: 1px;
  }

  &__notification-amount {
    top: 0px;
    right: 0px;
    height: 20px;
    min-width: 20px;
    width: fit-content;
    font-size: var(--app-font-size-label);
    padding: 4px 3px;
    white-space: nowrap;
  }

  &__backdrop {
    z-index: -1;
    display: block;
    @include position-corner(calc(var(--backdrop-size) * -1));
    position: absolute;
    border-radius: var(--backdrop-radius, 50%);
    background: var(--backdrop-color, transparent);
  }
  &__errors {
    @include icon-after($icon-warning);
  }
}
</style>
